import simg from '../images/service/1.png'
import simg2 from '../images/service/2.png'
import simg3 from '../images/service/3.png'
import simg4 from '../images/service/4.png'

import sImgS1 from '../images/service-single/img-1.jpg'
import sImgS2 from '../images/service-single/img-2.jpg'
import sImgS3 from '../images/service-single/img-3.jpg'
import sImgS4 from '../images/service-single/img-4.jpg'

import sSingleimg1 from '../images/service-single/2.jpg'
import sSingleimg2 from '../images/service-single/3.jpg'




const Services = [
    {
        Id: '1',
        sImg:simg,
        sImgS:sImgS1,
        sTitle: 'Silver Credit Card',
        description:'Basic benefits, moderate rewards. Ideal for building credit or occasional use with standard perks.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-advisor',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '2',
        sImg:simg2,
        sImgS:sImgS2,
        sTitle: 'Gold Credit Card',
        description:'Exclusive perks, luxury rewards, VIP services, travel benefits, and enhanced security for discerning individuals.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-team',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '3',
        sImg:simg3,
        sImgS:sImgS3,
        sTitle: 'Diamond Credit Card',
        description:'Elite perks, exclusive rewards, luxury benefits. Elevate your spending experience with unparalleled privileges. Apply now for prestige.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-taxes',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '4',
        sImg:simg4,
        sImgS:sImgS4,
        sTitle: 'Platinum Credit Card',
        description:'Elite perks, premium rewards, exclusive benefits, and superior services for discerning customers. Elevate your financial experience today.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-tax',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    }
]    

export default Services;