import React, { Fragment, useContext, useEffect } from 'react';
import Navbar3 from '../../components/Navbar3/Navbar3';
import Footer2 from '../../components/footer2/Footer2';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Logo from '../../images/logo.png';
import PersonalInformation from '../../components/Contactpage/PersonalInformation';
import IdentityInformation from '../../components/Contactpage/IdentityInformation';
import { Step, Stepper } from 'react-form-stepper';
import { useState } from 'react';
import LoginPage from '../LoginPage';
import UserContext from '../../components/ContextApi/UserContext';
import GlobalContext from '../../components/ContextApi/GlobalContext';

import UserDashboard from './UserDashboard';
import Cards from './Cards';
import { CustomLoader } from '../../components/common/CustomLoader';
import { clearLocalData, getLocalDataAsObject } from '../../utils/CoustomStorage';
import PageTitle from '../../components/pagetitle/PageTitle';

const ApplyPage = () => {
  const [active, setActive] = useState(0);
  const { user } = useContext(UserContext);
  const { globalDetails } = useContext(GlobalContext);
  const [token, setToken] = useState(false);

  useEffect(() => {
    let token = getLocalDataAsObject('token');
    if (!token) {
      clearLocalData();
      return window.location.replace('/');
    }
    setToken(true);
    setTimeout(() => {
      setActive(1);
      if (!user?.userDetail?.isPersonalInformation) {
        setActive(1);
      } else if (!user?.userDetail?.isIdentityInformation) {
        setActive(2);
      } else {
        setActive(3);
      }
    }, 1000);
  }, [user]);
  const steperChange = (val) => {
    setActive(val);
  };

  return (
    <Fragment>
      {token && (
        <>
          <Navbar3 Logo={Logo} />
          {/* {active !== 3 &&  <PageTitle pageTitle={'Processing Application'} pagesub={'Apply'} />} */}
          <div style={{ marginTop: '12%', minHeight: '500px' }} className='limit_btn_css'>
            <Stepper activeStep={active}>
              <Step label='Personal Details'></Step>
              <Step label='Identity Details'></Step>
              <Step label='Get Your Cards'></Step>
            </Stepper>
            {active == 0 && <CustomLoader steperChange={steperChange} />}
            {active == 1 && <PersonalInformation steperChange={steperChange} />}
            {active == 2 && <IdentityInformation steperChange={steperChange} />}
            {active == 3 && <UserDashboard globalDetails={globalDetails} user={user} steperChange={steperChange} />}
          </div>
          <Footer2 />
          <Scrollbar />
        </>
      )}
    </Fragment>
  );
};
export default ApplyPage;
