
const AdminDashboardCards = ({label, count}) => {

    return (
            <div class="card cardAdminDashboard" style={{"width": "15rem", display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                <h2>{label}</h2>
                <h2>{count}</h2>

            </div>
    )
}
export default AdminDashboardCards