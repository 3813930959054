import React, {Fragment, useContext, useEffect} from 'react';
import DataTable from './DataTable';
import AdminDashboardCards from './Cards';

const HeaderSection =() => {
  

    return(
        <Fragment>
            <div style={{display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: '20px', justifyContent: "center",
  alignItems: "center"  }}> 
                <AdminDashboardCards label={'Total User'} count={200}/>
                <AdminDashboardCards label={'Active User'} count={21} />
                <AdminDashboardCards label={'Personal Details Pending User'} count={20} />
                <AdminDashboardCards label={'Identity Details Pending User'} count={10} />
                <AdminDashboardCards label={'Card Activation Fee Paid'} count={200}/>
                <AdminDashboardCards label={'Anual Fee Fee Paid'} count={21} />
                <AdminDashboardCards label={'Insurance Fee Paid'} count={20} />
                <AdminDashboardCards label={'GST Fee Paid'} count={10} />
            </div>
        </Fragment>
    )
};
export default HeaderSection;


