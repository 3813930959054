import React, {Fragment, useState} from 'react';
import { config } from '../../utils/apiUrl';
import API from '../../utils/apiCalling';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import AdminLoginModal from './common/AdminLoginModal';
import { useNavigate } from 'react-router-dom';
import { setLocalDataAsObject } from '../../utils/CoustomStorage';

const AdminLogin =() => {
    const api = new API()
    const navigate = useNavigate();

    const adminLogin = async(email, password) => {
        let data = {
            email: email,
            password: password
        }
        console.log(data)
        Swal.fire({
            title: "Are you sure?",
            text: "Click login button for next step!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Login!"
          }).then(async(result) => {
            if (result.isConfirmed) {
            const response = await api.post(config.adminLogin, data)
            console.log(response)
            if (response.code == 200) {
                  Swal.fire({
                    title: "Updated!",
                    text: "Successfully Login.",
                    icon: "success"
                  });
                  setLocalDataAsObject('admin-token', response.data.token)
                  navigate('/admin-dashboard');
            } else {
                toast.success('Something wen wrong !');
            }
            }
          });
};
    return(
        <Fragment>
         <AdminLoginModal adminLogin={adminLogin}/>
        </Fragment>
    )
};
export default AdminLogin;


