import { useContext } from "react"
import API from "../utils/apiCalling"
import UserContext from "../components/ContextApi/UserContext"
import { getLocalDataAsObject } from "../utils/CoustomStorage"
import { config } from "../utils/apiUrl"
import { toast } from "react-toastify"

const useGetProfileDetails = () =>{
    const {user, setUser} = useContext(UserContext)
    const api = new API()
    const getUserProfile = async() => {
        let token = await getLocalDataAsObject("token")
        if(!token) {
            return
        }
        const response = await api.get(config.getProfile, false, {})
        if (response.code == 200) {
            setUser(response?.data)
        } else {
            setUser(null)
            // toast.success('Something wen wrong !');
        }
    }
    return { getUserProfile }
}
export default useGetProfileDetails