import React, {Fragment, useContext, useEffect} from 'react';
import DataTable from './DataTable';
import AdminDashboardCards from './Cards';

const UserDetails =({allUserDetails, updateUserDetails, deleteUser}) => {
  

    return(
        <Fragment>
                <DataTable allUserDetails={allUserDetails} updateUserDetails={updateUserDetails} deleteUser={deleteUser}/>
        </Fragment>
    )
};
export default UserDetails;


