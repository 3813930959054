import React from 'react';
import { Link } from 'react-router-dom';
import abimg2 from '../../images/slider/cat.png';

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const About = (props) => {
  return (
    <section className={`wpo-about-section section-padding ${props.abClass}`}>
      <div className='container'>
        <div className='row align-items-center'>
          <div
            className='col-lg-6 col-md-12 col-12'
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <div className='wpo-about-wrap'>
              <div className='wpo-about-imgs'>
                <div className='div_back'>
                  <div className='div_frunt'>
                    <img src={abimg2} alt='' className='img_style_animiOne' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-md-12 col-12'>
            <div className='wpo-about-text'>
              <h4>Welcome To NexaPayCard Consultancy Comapny</h4>
              <h2>We are trusted consulting company,</h2>
              <p>
                Welcome to NexaPayCard Credit Cards: Smart solutions for modern spending. Explore our range of
                innovative cards tailored to your financial needs. Start maximizing your rewards today!
              </p>
              <Link onClick={ClickHandler} className='theme-btn' to='/about'>
                More About Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
