import React, { Fragment, useContext, useEffect, useState } from 'react';
import Cards from './Cards';
import { Button2 } from '../../components/common/Button2';
import PayModal from './payModal';
import BankDetails from '../../components/Contactpage/BankDetails';

const UserDashboard = ({ user, globalDetails }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [fee, setFee] = useState(0);
  console.log('user details', user);
  const toggle = (amount) => {
    setFee(amount);
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional, adds smooth scrolling behavior
    });
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      var flipCard = document.getElementById('flip-card');
      flipCard.classList.toggle('flipped');
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Fragment>
      <p style={{ textAlign: 'center', fontWeight: '900', fontSize: '30px', color: 'blue' }}>
        Congratulations! {user?.name}
      </p>
      <p style={{ textAlign: 'center', fontWeight: '900', fontSize: '30px', color: '#6F4E37' }}>
        Your Card Application Has Been Approved
      </p>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto' }}>
        <div class='flip-card' id='flip-card'>
          <div class='flip-card-inner'>
            <div class='flip-card-front'>
              <p className='flip-card-front-name'>{user?.name}</p>
              <p className='flip-card-front-number'>7765 2565 7849 4756</p>
              <p className='flip-card-front-expiredate'>11/27</p>
              <img
                src='./images/1.png'
                alt='Image 1'
                style={{ height: '300px', width: '500px', borderRadius: '20px' }}
              />
            </div>
            <div class='flip-card-back'>
              <p className='flip-card-back-name'>{user?.name}</p>
              <p className='flip-card-back-number'>7765 2565 7849 4756</p>
              <p className='flip-card-back-expiredate'>11/27</p>
              <img
                src='./images/2.png'
                alt='Image 2'
                style={{ height: '300px', width: '500px', borderRadius: '20px' }}
              />
            </div>
          </div>
        </div>
      </div>
      <p style={{ textAlign: 'center', fontWeight: '900', fontSize: '25px', color: '#6F4E37' }}>
        Your Credit Card Limit RS:{user?.userDetail?.approvedLimit ? user?.userDetail?.approvedLimit : 25000} INR
      </p>
      <div style={{ textAlign: 'center', margin: '50px' }}>
        {!user.userDetail.isActivationFeePaid ? (
          <Button2 clickHandler={toggle} amount={user.userDetail.activationFee || 998} label={'Activate Now'} />
        ) : !user.userDetail.isInsuranceFeePaid ? (
          <Button2 clickHandler={toggle} amount={user.userDetail.insuranceFee || 998} label={'Pay Insurance Fee'} />
        ) : user.userDetail.isBankDetailsFiled == 'false' ? (
          <BankDetails />
        ) : !user.userDetail.isGstFeePaid ? (
          <Button2 clickHandler={toggle} amount={user.userDetail.gstFee || 998} label={'Pay GST Fee'} />
        ) : !user.userDetail.isFdFeePaid ? (
          <Button2 clickHandler={toggle} amount={user.userDetail.fdFee || 998} label={'Activate FD'} />
        ) : !user.userDetail.isWaletUpdateFeePaid ? (
          <Button2 clickHandler={toggle} amount={user.userDetail.waletUpdateFee || 998} label={'Wallet Update Fee'} />
        ) : !user.userDetail.isMemberShipFeePaid ? (
          <Button2 clickHandler={toggle} amount={user.userDetail.memberShipFee || 998} label={'Activate Membership'} />
        ) : !user.userDetail.isChamberShipFeePaid ? (
          <Button2
            clickHandler={toggle}
            amount={user.userDetail.chambershipFee || 998}
            label={'Activate Chambership'}
          />
        ) : (
          <h2>Your card application under in processing, It will take upto 7 working days</h2>
        )}
      </div>
      <div className='container' style={{ marginTop: '70px', width: '60%', textAlign: 'justify' }}>
        <p>
          Welcome to NexaPayCard Credit Card credit card family India's leading NBFC finance company with more than 2
          lakh happy customer in all over India.Your credit card limit is{' '}
          {user?.userDetail?.approvedLimit ? user?.userDetail?.approvedLimit : 25000} with a return period of 60 days
          with low interest rates.For your card activation plz make a one time card making delivery and processing
          charges 998 INR.You can use your vartual card after paying these charges and than make card insurance and use
          your card. Physical card will be delivered in 3 to 5 working days.Plz pay activation charges on companies QR
          code shown below down and your card will be ready to use.
        </p>
      </div>

      <div style={{ margin: 'auto', width: '58%' }}>
        <p>
          <img src='/images/76.png' style={{ width: '25px', margin: '10px' }} />
          Benefits Of Card
        </p>
        <p>
          <img src='/images/76.png' style={{ width: '25px', margin: '10px' }} />
          Get instant 2000 Amazon shopping voucher.
        </p>
        <p>
          <img src='/images/76.png' style={{ width: '25px', margin: '10px' }} />
          Get instant 1000 Flipkart shopping voucher.
        </p>
        <p>
          <img src='/images/76.png' style={{ width: '25px', margin: '10px' }} />
          Get 2 % assured cash back on HP petrol pumps all over India.
        </p>
        <p>
          <img src='/images/76.png' style={{ width: '25px', margin: '10px' }} />
          No annual fees and lifetime free.
        </p>
        <p>
          <img src='/images/76.png' style={{ width: '25px', margin: '10px' }} />
          110000 cash withdrawal limit.
        </p>
      </div>

      <PayModal globalDetails={globalDetails} fee={fee} modal={isOpen} toggle={toggle} />
    </Fragment>
  );
};
export default UserDashboard;
