import React, { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import { config } from '../../utils/apiUrl';
import API from '../../utils/apiCalling';
import { toast } from 'react-toastify';
import { setLocalDataAsObject } from '../../utils/CoustomStorage';
import { useNavigate } from 'react-router-dom';
import useGetProfileDetails from '../../hooks/useProfileDetails';
import Swal from 'sweetalert2';


const PersonalInformationForm = ({steperChange}) => {
    const push = useNavigate()
    const { getUserProfile } = useGetProfileDetails()
    const api = new API()
    const [forms, setForms] = useState({
        father_name: '',
        // email: '',
        dob: '',
        employment_type: '',
        gender: '',
        pin_code: '',
        address: '',
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = async(e) => {
        e.preventDefault();
        // steperChange(2)
        console.log(validator)
        if (validator.allValid()) {
            validator.hideMessages();
            const formData = new FormData();
            formData.append('fatherName', forms.father_name);
            // formData.append('email', forms.email);
            formData.append('dob', forms.dob);
            formData.append('employmentType', forms.employment_type);
            formData.append('gender', forms.gender);
            formData.append('pinCode', forms.pin_code);
            formData.append('address', forms.address);
            formData.append('isPersonalInformation', true)
            Swal.fire({
                title: "Are you sure?",
                text: "Are you sure to save personal details!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes!"
              }).then(async(result) => {
                if (result.isConfirmed) {
            const response = await api.postUpload(config.updateDetail, formData)
            console.log("response is", response)
            if (response.code == 200) {
                steperChange(0)
                Swal.fire({
                    title: "Saved!",
                    text: "Successfully saved personal information !.",
                    icon: "success"
                  });
                getUserProfile()
            } else {
                toast.success('Something wen wrong !');
            }
            }
        });
        } else {
            validator.showMessages();
        }
    };

    return (
        <form className="contact-validation-active" >
                <h2 align="center" style={{color: 'white', marginBottom: '20px'}}>PERSONAL DETAILS</h2>
            <div className="row">
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <input
                            value={forms.father_name}
                            type="text"
                            name="father_name"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Father's Name" 
                            style={{borderRadius: '30px',  color:'#fff', fontSize: '17px',outline:'none'}}
                            />
                        {validator.message('father_name', forms.father_name, 'required|alpha_space')}
                    </div>
                </div>
                {/* <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.email}
                            type="email"
                            name="email"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your Email" 
                            style={{borderRadius: '30px',  color:'#fff', fontSize: '17px', outline:'none'}}
                            />
                        {validator.message('email', forms.email, 'required|email')}
                    </div>
                </div> */}
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.dob}
                            type="date"
                            name="dob"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your DOB" 
                            style={{borderRadius: '30px',  color:'#fff', fontSize: '17px', outline:'none'}}
                            />
                        {/* {validator.message('dob', forms.dob, 'required|phone')} */}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <select
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            value={forms.employment_type}
                            type="text"
                            name="employment_type"
                            style={{borderRadius: '30px',  color:'#fff', fontSize: '17px', outline:'none'}}
                            >
                            <option>Employment Type</option>
                            <option>Full-Time Employees</option>
                            <option>Part-Time Employees</option>
                            <option>Contract Employees</option>
                            <option>Self Employees</option>
                        </select>
                        {/* {validator.message('employment_type', forms.employment_type, 'required')} */}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <select
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            value={forms.gender}
                            type="text"
                            name="gender"
                            style={{borderRadius: '30px',  color:'#fff', fontSize: '17px', outline:'none'}}
                            >
                            <option>Gender</option>
                            <option>Male</option>
                            <option>Female</option>
                            <option>Others</option>
                        </select>
                        {/* {validator.message('Gender', forms.Gender, 'required')} */}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.pin_code}
                            type="number"
                            name="pin_code"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Pin Code" 
                            style={{borderRadius: '30px',  color:'#fff', fontSize: '17px', outline:'none'}}
                            />
                        {/* {validator.message('pin_code', forms.pin_code, 'required|phone')} */}
                    </div>
                </div>

                <div className="col col-lg-12 col-12">
                    <textarea
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                        value={forms.address}
                        style={{height: '80px', borderRadius: '30px',  color:'#fff', fontSize: '17px', outline:'none'}}
                        type="text"
                        name="address"
                        placeholder="Address"
                        >
                    </textarea>
                    {/* {validator.message('address', forms.address, 'required')} */}
                </div>
            </div>
            <div className="submit-area">
                <button type="button" onClick={submitHandler} className="theme-btn">Next</button>
            </div>
        </form >
    )
}

export default PersonalInformationForm;