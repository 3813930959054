import { useContext } from "react"
import API from "../utils/apiCalling"
import { config } from "../utils/apiUrl"
import GlobalContext from "../components/ContextApi/GlobalContext"

const useGetGlobalDetails = () => {
    const {setGlobalDetails} = useContext(GlobalContext)
    const api = new API()
    const getGlobalDetails = async() => {
        const response = await api.get(config.getGlobalSetting, false, {})
        if (response.code == 200) {
            setGlobalDetails(response?.data)
        } else {
            setGlobalDetails(null)
            // toast.success('Something wen wrong !');
        }
    }
    return { getGlobalDetails }
}
export default useGetGlobalDetails