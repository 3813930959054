import React, { useContext, useEffect } from 'react';
import AllRoute from '../router'
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../utils/apiCalling';
import { getLocalDataAsObject } from '../../utils/CoustomStorage';
import { config } from '../../utils/apiUrl';
import UserContext from '../../components/ContextApi/UserContext';
import useGetProfileDetails from '../../hooks/useProfileDetails';
import useGetGlobalDetails from '../../hooks/useGetGlobalDetails';


const App = () => {
 const { getUserProfile } = useGetProfileDetails()
 const { getGlobalDetails } = useGetGlobalDetails()

  useEffect(()=> {
    getUserProfile()
    getGlobalDetails()

  },[])

  return (
    <div className="App" id='scrool'>
          <AllRoute/>
          <ToastContainer/>
    </div>
  );
}

export default App;